var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "설비정비목록",
          columns: _vm.grid.columns,
          data: _vm.grid.data,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          merge: _vm.grid.merge,
          rowKey: "minEquipmentMaintenanceId",
        },
        on: { linkClick: _vm.linkClick },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props }) {
              return [
                props.row["maintenanceStatusCd"]
                  ? [
                      _c(
                        "q-chip",
                        {
                          class:
                            props.row.maintenanceStatusCd === "MCSC000020"
                              ? "blinking"
                              : null,
                          attrs: {
                            color: _vm.setTagColor(
                              props.row.maintenanceStatusCd
                            ),
                            outline: "",
                            square: "",
                            "text-color": "white",
                          },
                        },
                        [
                          props.row.maintenanceStatusCd === "MCSC000020"
                            ? _c("q-avatar", {
                                attrs: {
                                  icon: "alarm",
                                  color: "red",
                                  "text-color": "white",
                                },
                              })
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.setTagName(props.row.maintenanceStatusCd)
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }